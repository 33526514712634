import styles from "./Company.module.css"
function MissaoVisao() {

    return (

        <div className={styles.container} >

            <div> <h1 className={styles.h3}>Missão</h1></div>

            <div className={styles.container}>

                <span>
                    <p>
                        Capacitar e certificar profissionais para operações seguras e eficientes, fornecendo treinamentos de qualidade que atendam às necessidades específicas de cada cliente e contribuam para o desenvolvimento sustentável das indústrias.

                    </p>

                </span>
            </div>
            <div> <h1 className={styles.h3}>Visão</h1></div>
            <div className={styles.container}>
                <span>
                    <p>Ser reconhecida como a principal referência em treinamentos de operação de equipamentos, destacando-se pela excelência técnica, inovação constante e compromisso inabalável com a segurança e o sucesso dos nossos clientes.

                    </p>
                </span>
            </div>
            <div> <h1 className={styles.h3}>Valores</h1></div>
            <div>
                <span>
                    <p>Comprometemo-nos a priorizar a segurança em todas as nossas operações e treinamentos, seguindo os mais altos padrões de segurança para proteger nossos clientes, colaboradores e comunidades.

                    </p>
                </span>
            </div>

        </div>


    )
}
export default MissaoVisao;