import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import Home from './components/pages/Home';
import Company from './components/pages/Company';
import Contact from './components/pages/Contact';
import Login from './components/pages/Login';
import Container from './components/layout/Container';
import Navbar from './components/layout/Navbar'
import Footer from './components/layout/Footer'
import InCompany from './components/pages/Cursos/InCompany'
import Comunidade from './components/pages/Cursos/Comunidade';
import Mecanica from './components/pages/Cursos/Mecanica';
import MissaoVisao from './components/pages/MissaoVisao';



function App() {
  return (

    <Router>
     <Navbar></Navbar>
      <Container customClass="min-heigth">
      <Routes>
      
          <Route exact path='/' element={<Home />} />
          <Route exact path='/Company' element={<Company />} />
          <Route exact path='/' element={<Contact />} />
          <Route exact path='/MissaoVisao' element={<MissaoVisao />} />
          <Route exact path='/Login' element={<Login />} />
          <Route exact path='/Cursos/InCompany' element={<InCompany />} />
          <Route exact path='/Cursos/Comunidade' element={<Comunidade />} />
          <Route exact path='/Cursos/Mecanica' element={<Mecanica />} />
       
      </Routes>
      </Container>
      <Footer></Footer>
      
    </Router >

  );
}

export default App;
