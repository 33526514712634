import styles from "./Company.module.css"
function Company() {

    return (

        <div className={styles.container} >

            <div> <h1 className={styles.h3}>Nossa Historia</h1></div>

            <div className={styles.container}>

                <span>
                    <p>A <strong>ALFA Treinamentos</strong>  foi fundada em Outubro de 2010
                        com o objetivo de oferecer treinamentos profissionalizantes
                        para COMUNIDADE e EMPRESAS, sempre oferecendo
                        treinamentos conforme exigência das Normas
                        Regulamentadoras do Ministério do Trabalho e Emprego
                        (MTE) e Maquinas Pesada.
                    </p>
                    <p>Atualmente contamos com três
                        unidades: Anápolis (Matriz), Goiânia (Filial 1) e Uberlândia
                        (Filial 3) onde atendemos tanto as três cidades quanto as
                        demais regiões solicitantes.

                    </p>
                    <p>A partir de Agosto de 2011 a ALFA Treinamentos
                        passou a fazer parte do Grupo GOLD Treinamentos Industriais
                        LTDA, no intuito de expandir seus treinamentos se
                        preocupando com a excelência e qualidade dos cursos
                        oferecidos e ministrados em toda a sua extensão.
                        Hoje contamos com uma equipe de Engenheiros
                        Mecânicos, Elétricos, Segurança do Trabalho, Técnico em
                        Segurança do Trabalho, Enfermeiros dentre outros.
                    </p>


                </span>
            </div>

        </div>


    )
}
export default Company