import styles from "./Cursos.module.css"
function Comunidade() {


    return (

        <div className={styles.container} >

            <div> <h1 className={styles.h3}>Comunidade</h1></div>


            <div className={styles.container}>
                <span>
                    <ul>
                        <li>Operador de Empilhadeiras (formação ou reciclagem)</li>
                        <li>Operador de Retroescavadeira</li>
                        <li>Operador de Pá carregadeira</li>
                        <li>Operador de Escavadeira Hidráulica</li>
                        <li>Operador de Caminhão Munck</li>
                        <li>Operador de Plataforma (Articulada ou Tesoura)</li>
                        <li>Operador de Bobcat</li>
                        <li>NR 35</li>
                        <li>Operador de Caldeira</li>
                    </ul>
                </span>
            </div>
        </div>
    )
}
export default Comunidade;