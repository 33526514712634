import styles from "./Cursos.module.css"

function InCompany() {


    return (

        <div className={styles.container} >

            <div> <h1 className={styles.h3}>In Company</h1></div>


            <div className={styles.container}>

                <span>
                    <ul>
                        <li>NR 23 - Brigada de Incêndio</li>
                        <li>Operador de Transpaleteira</li>
                        <li>NR 33 - Trabalho em espaço confinado</li>
                        <li>NR 10 - Segurança em Instalações e Serviços em Eletricidade</li>
                        <li>SEP - Segurança em Sistema Elétrico de Potência</li>
                        <li>NR 18 - Curso de Condições e Meio Ambiente de Trabalho na Indústria da Construção</li>
                        <li>NR 12 - Martelo Pneumático</li>
                        <li>Formação de CIPA</li>
                        <li>NR 06 - EPI</li>
                        <li>Operador de Ponte Rolante e Talha                 </li>
                        <li>Operado de Rebocador                              </li>
                        <li>Operador de Empilhadeiras (formação ou reciclagem)</li>
                        <li>Operador de Retroescavadeira                      </li>
                        <li>Operador de Pá carregadeira                       </li>
                        <li>Operador de Escavadeira Hidráulica                </li>
                        <li>Operador de Caminhão Munck                        </li>
                        <li>Operador de Plataforma (Articulada ou Tesoura)    </li>
                        <li>Operador de Bobcat                                </li>
                        <li>NR 35 - Trabalho em Altura                        </li>
                        <li>NR 13 - Operador de Caldeira                      </li>



                    </ul>

                </span>


            </div>
        </div>
    )
}
export default InCompany