import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'
import styles from './Footer.module.css'
import { Link } from 'react-router-dom'

function Footer() {


    return (

        <footer className={styles.footer}>

            <ul className={styles.social_list}>

                <li><Link to="https://www.facebook.com/alfa.treinamentos.empilhadeira/"><FaFacebook /></Link></li>
                <li><Link to="https://instagram.com/alfa.treinamentos.emp/"><FaInstagram /></Link></li>
                <li><Link to="https://www.linkedin.com/in/alfa-treinamentos-62071497"><FaLinkedin /></Link></li>
            </ul>
            <p className={styles.copy_rigth}> Alfa Treinamentos &copy; 2024 - Todos os direitos reservados.</p>
            <p>Created By VW Soluções LTDA 2024.</p>
        </footer>
    )
}
export default Footer