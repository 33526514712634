import styles from "./Cursos.module.css"
function Mecanica() {


    return (
        <div className={styles.container} >

            <div> <h1 className={styles.h3}>Mecanica</h1></div>


            <div className={styles.container}>

                <span>
                    <p>
                        Bem-vindo à Alfa Treinamentos com Mecânica especializada, onde a excelência encontra a engenharia. Somos especializados na manutenção e reparo de empilhadeiras e plataformas elevatórias. Com uma equipe de mecânicos altamente qualificados e experientes, nos dedicamos a fornecer serviços de alta qualidade para manter seus equipamentos funcionando com eficiência máxima.
                    </p>
                    <p>
                        A Alfa treinamentos entende a importância de equipamentos de manuseio de materiais confiáveis. Por isso, oferecemos uma gama completa de serviços de reparo e manutenção, desde inspeções de rotina até reparos complexos. Utilizamos apenas peças de reposição de alta qualidade para garantir a durabilidade e o desempenho do seu equipamento.
                    </p>
                    <p>
                        Seja uma empilhadeira ou uma plataforma elevatória, nossa equipe tem a experiência e o conhecimento para diagnosticar e resolver problemas rapidamente, minimizando o tempo de inatividade e mantendo sua operação em movimento. Confie em nós para todas as suas necessidades de serviço de empilhadeira e plataforma elevatória. Estamos aqui para ajudar você a manter seus negócios em movimento.

                    </p>
                </span>


            </div>
        </div>
    )
}
export default Mecanica