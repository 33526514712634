import { Link } from 'react-router-dom'
import { FaWhatsapp, FaPhone, FaRegUser } from 'react-icons/fa'
import Container from './Container'
import styles from "./Navbar.module.css"
import logo from "../img/ALFA.png"

function MenuOnEnter() {
    var x = document.getElementById('Cursos');
    if (x.style.visibility === 'hidden') {
        x.style.visibility = 'visible';
    }
    else {
        x.style.visibility = 'hidden';
    }
}

function MenuOnEnter2() {
    var x = document.getElementById('Pecas');
    if (x.style.visibility === 'hidden') {
        x.style.visibility = 'visible';
    }
    else {
        x.style.visibility = 'hidden';
    }
}

function Navbar() {
    return (
        <header>
            <nav className={styles.navbar}>
                <Container className={styles.menu_container}>
                    <div className={styles.first_level}>
                        <ul className={styles.restricted_area}>
                            <li className={styles.iconsSocial} ><Link to="/" > <FaPhone /> Me Liga</Link></li>
                            <li className={styles.iconsSocial}> <Link to="https://api.whatsapp.com/send?phone=5562994671088" ><FaWhatsapp /> WhatsApp</Link></li>
                            <li className={styles.iconsSocial}> <Link to="/Login" ><FaRegUser /> Entrar</Link></li>
                        </ul>
                    </div>
                    <div className={styles.hdivider}></div>
                    <div className={styles.secont_level}>
                        <ul className={styles.list}>
                            <li className={styles.logoAlfa}><Link to="/" ><img src={logo} alt="Alfa Treinamentos" title="Alfa logo" /></Link></li>
                            <li className={styles.itemPecas} onMouseEnter={MenuOnEnter2}>Peças e Acessórios
                                <div id='Pecas' className={styles.subMenu}>
                                    <ul >
                                        <li><Link to="/Pecas/Arrefecimento">Arrefecimento </Link></li>
                                        <li><Link to="/Pecas/Componentes externos">Componentes Externos </Link></li>
                                        <li><Link to="/Pecas/Correias e Mangueiras">Correias e Mangueiras </Link></li>
                                        <li><Link to="/Pecas/Direcional e Diferencial">Direcional e Diferencial </Link></li>
                                        <li><Link to="/Pecas/Eletrica">Elétrica </Link></li>
                                        <li><Link to="/Pecas/Escapamento">Escapamento </Link></li>
                                        <li><Link to="/Pecas/Filtros">Filtros </Link></li>
                                        <li><Link to="/Pecas/Freio">Freio </Link></li>
                                        <li><Link to="/Pecas/Hidraulica">Hidráulica </Link></li>
                                        <li><Link to="/Pecas/Kits para revisao">Kits para revisão </Link></li>
                                        <li><Link to="/Pecas/Motor">Motor </Link></li>
                                        <li><Link to="/Pecas/Pecas para transpaletes">Peças para transpaletes </Link></li>
                                        <li><Link to="/Pecas/Pneus e rodas">Pneus e rodas </Link></li>
                                        <li><Link to="/Pecas/Suspensao">Suspensão </Link></li>
                                        <li><Link to="/Pecas/Torres">Torres </Link></li>
                                        <li><Link to="/Pecas/Transmissao">Transmissão </Link></li>

                                    </ul>
                                </div>
                            </li>
                            <li className={styles.item}> <Link to="/Vendas" >Vendas</Link></li>
                            <li className={styles.item}><Link to="/" >Destaques</Link></li>
                            <li className={styles.itemCurso} onMouseEnter={MenuOnEnter}>Cursos
                                <div id='Cursos' className={styles.subMenu}>
                                    <ul >
                                        <li><Link to="/Cursos/inCompany">In Company </Link></li>
                                        <li><Link to="/Cursos/Comunidade">Comunidade</Link></li>
                                        <li><Link to="/Cursos/Mecanica">Mecanica</Link></li>
                                    </ul>
                                </div>
                            </li>
                            <li className={styles.item}><Link to="/MissaoVisao" >Missão e Visão</Link></li>
                            <li className={styles.item}><Link to="/Company" >Quem Somos</Link></li>
                            <li className={styles.item}> <Link to="/Contact" >Contato</Link></li>
                        </ul>
                    </div>
                </Container>
            </nav>
        </header>
    )
}
export default Navbar