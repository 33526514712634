import styles from "./Home.module.css"
import bannerGenerico from "../img/bannergenerico.jpg"
import bannerEmpilhadeira from "../img/empilhadeira.jpg"
import bannerCaldeira from "../img/caldeira.jpg"
import bannerMunck from "../img/munck.jpg"
import bannerPaCarregadeira from "../img/pacarregadeira.jpg"
import bannerPlataforma from "../img/plataforma.jpg"
import bannerRetroescavadeira from "../img/retroescavadeira.jpg"
import bannerEscavadeiraHidraulica from "../img/EscavadeiraHidraulica.jpg"
import bannerbotcat from "../img/botcat.jpg"
import bannerResgatealtura from "../img/Resgatealtura.jpg"

function Home() {

    return (<div>
        <section className={styles.home_container}>
            <div className={styles.banner_container}>
                <div className={styles.banner_text}>
                    <div className={styles.divText}>
                        <div className={styles.subheader}>Chance imperdível</div>
                        <h1>Conclua seu curso</h1>
                        {/* <h2>seu curso</h2> */}
                        <div className={styles.divisao}>
                            <div className={styles.h1}>
                                <span className={styles.yellow}>em 24 hrs<sup>*</sup></span>
                            </div>
                            <small className={styles.subheader}><br /></small>
                        </div>
                        <div className={styles.h5}>Cursos com certificação válida em todo território nacional<sup> </sup></div>

                        <div className={styles.fl_body}>
                            Consulte as modalidades de cursos disponíveis para essa opção!
                        </div>
                    </div>
                </div>

                <div className={styles.banner_image}>
                    <img src={bannerGenerico}></img>

                    <div className={styles.hero_badge}>
                        <div className={styles.badgeOne}>
                            "venha para a"

                        </div>
                        <div className={styles.badgeTwo}>
                            "Alfa Treinamentos"
                        </div>
                    </div>
                </div>
            </div>


            <div className={styles.bannerBack}>
                <svg preserveAspectRatio="none" viewBox="0 0 1302 189" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 189C0 189 126.5 0 313 0C499.5 0 527.5 189.943 816.5 91C1105.5 -7.9426 1302 139.71 1302 139.71V189H0Z" fill='#3e3f954b'></path>
                </svg>

            </div>
            <div className={styles.wave_divider}>

            </div>

        </section>
        <section className={styles.ribbon}>
            <div className={styles.ribbon_container}>
                <div className={styles.ribbon_col}>
                    <div className={styles.title}>CURSOS QUE CABEM NO SEU BOLSO</div>
                    <p>Comece o seu curso, sem se afogar nos boletos</p>

                </div>
                <div className={styles.ribbon_col}>
                    <div className={styles.mensais}>Mensais de até </div>
                    <div className={styles.ribbon_chip_wrapper}>

                        <div className={styles.ribbon_chip}>
                            <div className={styles.price}>
                                R$
                                <span>300</span>
                                ,00
                            </div>
                            <a role="button" className={styles.btn}>Ver Cursos</a>
                        </div>
                        <div className={styles.ribbon_chip}>
                            <div className={styles.price}>
                                R$
                                <span>550</span>
                                ,00
                            </div>
                            <a role="button" className={styles.btn}>Ver Cursos</a>
                        </div>
                        <div className={styles.ribbon_chip}>
                            <div className={styles.price}>
                                R$<span>650</span>,00
                            </div>
                            <a role="button" className={styles.btn}>Ver Cursos</a>
                        </div>
                        <div className={styles.ribbon_chip}>
                            <div className={styles.price}>
                                R$<span>850</span>,00
                            </div>
                            <a role="button" className={styles.btn}>Ver Cursos</a>
                        </div>


                    </div>


                </div>
            </div>


        </section>
        <div className={styles.wave_dividerFlip}>

        </div>
        <section className={styles.emAlta}>
            <div className={styles.container}>
                <div className={styles.cardsGrid}>
                    <div className={styles.gridItem}>
                        <div className={styles.gridItemContent}>
                            <div className={styles.titleGrid}>
                                Plataforma Elevatória móvel para trabalho</div>
                            <div className={styles.fBody}><b>Conquiste novas perspectivas com nosso curso de operação de plataformas elevatórias. Aprenda com os melhores, opere com segurança e destaque-se no mercado. Expertise Profissional, Certificação Reconhecida, Práticas de Segurança..</b>
                                <br></br>
                                {/* <b className={styles.fBody}>R$ 250,00 </b> */}
                            </div>
                            <button className={styles.btn}>Inscreva-se</button>
                        </div>
                        <img src={bannerPlataforma} loading="lazy" draggable="false" width="100%" height="164px"></img>
                    </div>
                    <div className={styles.gridItem}>
                        <div className={styles.gridItemContent}>
                            <div className={styles.titleGrid}>
                                Empilhadeira</div>
                            <div className={styles.fBody}><b>O curso de Empilhadeira é projetado para fornecer aos operadores uma sólida base em práticas de segurança, operação adequada e conhecimento dos regulamentos aplicáveis. Este curso é uma combinação de instrução teórica e prática.</b>
                                <br></br>
                                {/* <b className={styles.fBody}>R$ 300,00 </b> */}
                            </div>
                            <button className={styles.btn}>Inscreva-se</button>
                        </div>
                        <img src={bannerEmpilhadeira} loading="lazy" draggable="false" width="100%" height="164px"></img>
                    </div>
                    <div className={styles.gridItem}>
                        <div className={styles.gridItemContent}>
                            <div className={styles.titleGrid}>
                                Operador de Pá carregadeira</div>
                            <div className={styles.fBody}><b>Este curso capacita os alunos a operar uma pá carregadeira de forma segura e eficaz. O curso cobre tópicos como inspeção de equipamentos, manutenção básica, técnicas de carregamento e descarregamento e procedimentos de segurança.
                            </b>
                                <br></br>
                                {/* <b className={styles.fBody}>R$ 550,00 </b> */}
                            </div>
                            <button className={styles.btn}>Inscreva-se</button>
                        </div>
                        <img src={bannerPaCarregadeira} loading="lazy" draggable="false" width="100%" height="164px"></img>
                    </div>
                    <div className={styles.gridItem}>
                        <div className={styles.gridItemContent}>
                            <div className={styles.titleGrid}>
                                Caldeira </div>
                            <div className={styles.fBody}><b>O curso de Caldeira oferece uma compreensão completa das caldeiras a vapor, incluindo operação, manutenção e solução de problemas. Os participantes aprenderão sobre a teoria da caldeira, regulamentos de segurança, controle de combustão e muito mais.</b>
                                <br></br>
                                {/* <b className={styles.fBody}>R$ 600,00 </b> */}
                            </div>
                            <button className={styles.btn}>Inscreva-se</button>
                        </div>
                        <img src={bannerCaldeira} loading="lazy" draggable="false" width="100%" height="164px"></img>
                    </div>
                    <div className={styles.gridItem}>
                        <div className={styles.gridItemContent}>
                            <div className={styles.titleGrid}>
                                Operador de Munck</div>
                            <div className={styles.fBody}><b>Este curso é destinado a operadores de guindastes articulados (Munck), proporcionando conhecimento sobre operação segura, inspeção diária, estabilidade do guindaste e técnicas de elevação. O curso combina teoria e prática para uma aprendizagem completa.
                            </b>
                                <br></br>
                                {/* <b className={styles.fBody}>R$ 650,00 </b> */}
                            </div>
                            <button className={styles.btn}>Inscreva-se</button>
                        </div>
                        <img src={bannerMunck} loading="lazy" draggable="false" width="100%" height="164px"></img>
                    </div>

                    <div className={styles.gridItem}>
                        <div className={styles.gridItemContent}>
                            <div className={styles.titleGrid}>
                                Operador de retroescavadeira</div>
                            <div className={styles.fBody}><b>O curso de Operador de Retroescavadeira fornece aos alunos as habilidades e conhecimentos necessários para operar uma retroescavadeira de forma segura e eficiente. O curso abrange tópicos como operação de equipamentos, técnicas de escavação, manutenção de máquinas e segurança no local de trabalho.
                            </b>
                                <br></br>
                                {/* <b className={styles.fBody}>R$ 650,00 </b> */}
                            </div>
                            <button className={styles.btn}>Inscreva-se</button>
                        </div>
                        <img src={bannerRetroescavadeira} loading="lazy" draggable="false" width="100%" height="164px"></img>
                    </div>

                    <div className={styles.gridItem}>
                        <div className={styles.gridItemContent}>
                            <div className={styles.titleGrid}>
                                Operador de Escavadeira Hidráulica</div>
                            <div className={styles.fBody}><b>O curso de Escavadeira Hidráulica da Alfa Treinamentos é projetado para fornecer aos participantes o conhecimento e as habilidades necessárias para operar escavadeiras hidráulicas de forma segura e eficiente. Este curso abrange desde os fundamentos da operação até técnicas avançadas, garantindo que os alunos estejam bem preparados para enfrentar os desafios do dia a dia no campo.
                            </b>
                                <br></br>
                                {/* <b className={styles.fBody}>R$ 650,00 </b> */}
                            </div>
                            <button className={styles.btn}>Inscreva-se</button>
                        </div>
                        <img src={bannerEscavadeiraHidraulica} loading="lazy" draggable="false" width="100%" height="164px"></img>
                    </div>

                    <div className={styles.gridItem}>
                        <div className={styles.gridItemContent}>
                            <div className={styles.titleGrid}>
                                Operador de BobCat</div>
                            <div className={styles.fBody}><b>O curso de BobCat da Alfa Treinamentos é ideal para aqueles que desejam aprender a operar mini carregadeiras de forma segura e eficaz. Este curso cobre todos os aspectos essenciais da operação de BobCats, desde a familiarização com os controles até a execução de tarefas práticas no campo.
                            </b>
                                <br></br>
                                {/* <b className={styles.fBody}>R$ 650,00 </b> */}
                            </div>
                            <button className={styles.btn}>Inscreva-se</button>
                        </div>
                        <img src={bannerbotcat} loading="lazy" draggable="false" width="100%" height="164px"></img>
                    </div>

                    <div className={styles.gridItem}>
                        <div className={styles.gridItemContent}>
                            <div className={styles.titleGrid}>
                                Curso de Resgate em Altura</div>
                            <div className={styles.fBody}><b>O Curso de Resgate em Altura NBR 16710 é um programa abrangente que combina teoria e prática para capacitar profissionais a realizar operações de resgate em altura de forma segura e eficiente. Este curso é essencial para aqueles que atuam em ambientes de alto risco e necessitam de conhecimentos avançados em técnicas de resgate.
                            </b>
                                <br></br>
                                {/* <b className={styles.fBody}>R$ 650,00 </b> */}
                            </div>
                            <button className={styles.btn}>Inscreva-se</button>
                        </div>
                        <img src={bannerResgatealtura} loading="lazy" draggable="false" width="100%" height="164px"></img>
                    </div>
                </div>
            </div>
        </section>
    </div>
    )
}

export default Home
